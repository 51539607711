import moment from "moment"

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]

export function capitalizeFirstLetter(str) {
  return str[0].toUpperCase() + str.slice(1).toLowerCase()
}

export function formatDate(year, month, day) {
  return `${year}-${(month.length === 1 ? "0" : "") + month}-${(day.length === 1 ? "0" : "") + day}`
}

export function formatDateUSCCB(date) {
  const dateParts = date.split("-")
  return `${dateParts[1]}${dateParts[2]}${dateParts[0].slice(-2)}`
}

export function getCurrentDate() {
  const dateParts = new Date().toLocaleDateString().split("/")
  return formatDate(dateParts[2], dateParts[0], dateParts[1])
}

export function getDateInDirection(date, direction) {
  const newDate = moment(date)
  direction === "+" ? newDate.add(1, "days") : newDate.subtract(1, "days")
  const dateParts = newDate.format("L").split("/")
  return formatDate(dateParts[2], dateParts[0], dateParts[1])
}

export function getPrintableDate(dateString) {
  const date = new Date(`${dateString}T00:00:00.000`)
  return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
}
