import React, { useState, useEffect } from "react"
import { Container, Dropdown, Icon, Image, Menu } from "semantic-ui-react"
import { Link } from "react-router-dom"
//import axios from "axios"

export default function AppHeader(props) {
  const [categories /*setCategories*/] = useState(["Prayers"])
  const [texts /*setTexts*/] = useState([])

  //const backendURL = `https://catholictexts-backend.fly.dev`
  const menuStyle = {
    border: "none",
    borderRadius: 0,
    boxShadow: "none",
    borderBottom: "1px solid #ccc",
    transition: "box-shadow 0.5s ease, padding 0.5s ease",
    marginBottom: 0
  }

  useEffect(() => {
    //loadCategories()
    //loadTextsWithNoCategory()
    // eslint-disable-next-line
  }, [])

  /*function loadCategories() {
    console.log("Entering loadCategories")
    axios.get(`${backendURL}/texts/categories`).then((response) => {
      setCategories(response.data)
    })
  } */

  /*function loadTextsWithNoCategory() {
    console.log("Entering loadTextsWithNoCategory")
    axios.get(`${backendURL}/texts/nocategory`).then((response) => setTexts(response.data))
  }*/

  const buildCategories = () =>
    categories.map((category) => (
      <Link key={category} to={`/categories/${category.toLowerCase()}`}>
        <Dropdown.Item>{category}</Dropdown.Item>
      </Link>
    ))

  const buildTexts = () =>
    texts.map((text) => (
      <Link key={text.alias} to={`/texts/${text.alias}`}>
        <Dropdown.Item>{text.title}</Dropdown.Item>
      </Link>
    ))

  const buildDropdownMenu = () => (
    <Dropdown text="Menu" pointing className="link item">
      <Dropdown.Menu>
        <Link to={"/readings"}>
          <Dropdown.Item>Readings for Date</Dropdown.Item>
        </Link>
        <Dropdown.Divider />
        <Menu.Header>Categories</Menu.Header>
        {buildCategories()}
        {/* <Dropdown.Divider />  */}
        {/* <Menu.Header>Other Texts</Menu.Header> */}
        {buildTexts()}
        <Dropdown.Divider />
        <div className="acknowledgment">
          <p>Aggregate content belows to its respective owner</p>
          <p>
            Question, comment, feedback?&nbsp;
            <a href="mailto:hello@catholictexts.org">Send us an email</a>
          </p>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )

  const buildMenu = () => (
    <Menu.Menu position="right">
      <Menu.Item position="right" onClick={props.toggleFont}>
        <Icon name="font" />
      </Menu.Item>
      <Menu.Item position="right" onClick={props.toggleBackgroundColor}>
        <Icon name="tint" />
      </Menu.Item>
      {buildDropdownMenu()}
    </Menu.Menu>
  )

  return (
    <Menu id="header" borderless style={menuStyle}>
      <Container text>
        <Link to={"/"}>
          <Menu.Item>
            <Image size="small" src="/images/CatholicTextsLogo.png" />
          </Menu.Item>
        </Link>
        {buildMenu()}
      </Container>
    </Menu>
  )
}
