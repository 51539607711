import React, { useState, useEffect } from "react"
import { Container, Loader, Table } from "semantic-ui-react"
import { Link } from "react-router-dom"
//import axios from "axios"

import * as helpers from "../helpers/helpers.js"

export default function Category(props) {
  const [texts, setTexts] = useState([])

  //const backendURL = `https://catholictexts-backend.fly.dev`
  const category = helpers.capitalizeFirstLetter(props.match.params.category)

  useEffect(() => {
    setTexts([
      {
        alias: "act-of-spiritual-communion",
        category: "catholictexts",
        subcategory: "",
        rank: "",
        title: "Act of Spiritual Communion"
      },
      {
        alias: "apostles-creed",
        category: "catholictexts",
        subcategory: "",
        rank: "",
        title: "Apostles' Creed"
      },
      {
        alias: "glory-be",
        category: "catholictexts",
        subcategory: "",
        rank: "",
        title: "Glory Be"
      },
      {
        alias: "hail-mary",
        category: "catholictexts",
        subcategory: "",
        rank: "",
        title: "Hail Mary"
      },
      {
        alias: "nicene-creed",
        category: "catholictexts",
        subcategory: "",
        rank: "",
        title: "Nicene Creed"
      },
      {
        alias: "our-father",
        category: "catholictexts",
        subcategory: "",
        rank: "",
        title: "Our Father"
      }
    ])
    //loadTextsForCategory()

    // eslint-disable-next-line
  }, [props.match.params.category])

  /*function loadTextsForCategory() {
    console.log("Entering loadTextsForCategory")
    axios.get(`${backendURL}/texts/${category}`).then((response) => setTexts(response.data))
  }*/

  return (
    <Container text style={{ marginTop: "35px", marginBottom: "35px" }}>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="2">{category}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {texts.length === 0 ? (
            <Table.Row>
              <Table.Cell key={"spinner"}>
                <Loader
                  active
                  inline="centered"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                />
              </Table.Cell>
            </Table.Row>
          ) : (
            texts.map((text) => (
              <Table.Row key={text.alias}>
                <Table.Cell>
                  <Link to={`/texts/${text.alias}`}>{text.title}</Link>
                </Table.Cell>
                {text.subcategory && <Table.Cell>{text.subcategory}</Table.Cell>}
              </Table.Row>
            ))
          )}
        </Table.Body>
      </Table>
    </Container>
  )
}
