import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Container, Header, Icon, Segment, Loader } from "semantic-ui-react"
import SemanticDatepicker from "react-semantic-ui-datepickers"
import axios from "axios"

import * as helpers from "../helpers/helpers.js"

export default function ReadingsAndReflection(props) {
  const history = useHistory()
  const [date, setDate] = useState(null)
  const [readings, setReadings] = useState(null)
  const [readingsLoaded, setReadingsLoaded] = useState(false)
  //const [reflection, setReflection] = useState(null)
  //const [reflectionLoaded, setReflectionLoaded] = useState(false)

  const isHomeTreatment = props.treatment === "home"
  const backendURL = "https://catholictexts-backend.fly.dev"

  // eslint-disable-next-line
  useEffect(() => setDate(props.date || helpers.getCurrentDate()), [])

  useEffect(() => {
    setReadings(null)
    setReadingsLoaded(false)
    //setReflection(null)
    //setReflectionLoaded(false)

    if (date) {
      loadReadings()
      //loadReflection()
    }
    // eslint-disable-next-line
  }, [date])

  function loadReadings() {
    console.log("Entering loadReadings")

    const url = `${backendURL}/readings/${date}`
    axios
      .get(url)
      .then((response) => {
        setReadings(response.data)
        setReadingsLoaded(true)
      })
      .catch((error) => setReadingsLoaded(true))
  }

  /* function loadReflection() {
    console.log("Entering loadReflection")

    const url = `${backendURL}/reflections/${date}`
    axios
      .get(url)
      .then((response) => {
        setReflection(response.data.reflection)
        setReflectionLoaded(true)
      })
      .catch((error) => setReflectionLoaded(true))
  } */

  function handleDateChange(direction) {
    const newDate = helpers.getDateInDirection(date, direction)
    history.push(`/readings/${newDate}`)
    setDate(newDate)
  }

  function handleDatepickerChange(event, data) {
    const date = new Date(data.value)
    const dateString = helpers.formatDate(
      date.getFullYear().toString(),
      (date.getMonth() + 1).toString(),
      date.getDate().toString()
    )
    if (date !== dateString) {
      setDate(dateString)
      history.push(`/readings/${dateString}`)
    }
  }

  const buildDatePicker = () =>
    !isHomeTreatment && (
      <div style={{ marginTop: "35px" }} align={"center"}>
        <Header as="h3">Date:</Header>
        <SemanticDatepicker
          value={new Date(`${date}T00:00:00.000`)}
          onChange={handleDatepickerChange}
          datePickerOnly={true}
          showToday={false}
          clearable={false}
        />
      </div>
    )

  const buildHeader = () =>
    readings ? (
      <div>
        {buildDatePicker()}
        <Header as="h1" style={{ marginBottom: "2px" }} textAlign={"center"}>
          {readings.dayInfo}
        </Header>
        <Header as="h3" style={{ marginTop: "0px" }} textAlign={"center"}>
          <span className={"navArrow"} onClick={() => handleDateChange("-")}>
            &#60;
          </span>

          <span style={{ marginLeft: "25px", marginRight: "25px" }}>
            {helpers.getPrintableDate(readings.date)}
          </span>

          <span className={"navArrow"} onClick={() => handleDateChange("+")}>
            &#62;
          </span>
        </Header>
        { /*<Container textAlign="center">
          <a href={"#readings"}>Today's Readings</a> |&nbsp;
          <a href={"#reflection"}>Today's Gospel Reflection</a>
        </Container> */ }
      </div>
    ) : null

  const buildReadingsContent = () =>
    readings.items.map((reading) => (
      <div
        key={reading.name}
        style={{
          paddingBottom: "25px",
          margin: "0 auto",
          maxWidth: "475px",
          fontSize: "12pt"
        }}>
        <Header as="h3">{reading.name}</Header>
        {reading.verseLink && (
          <a href={reading.verseLink} target={"_blank"} rel="noreferrer">
            {reading.verse} <Icon link name="external" size="small" />
          </a>
        )}
        <div dangerouslySetInnerHTML={{ __html: reading.text }}></div>
      </div>
    ))

  const buildReadingsArea = () => (
    <Segment id="readings">
      <Header as="h2" textAlign={"center"} style={{ textDecoration: "underline" }}>
        Today's Readings
      </Header>
      {readings && readings.items.length > 0 ? (
        <div>
          {buildReadingsContent()}
          <Container textAlign="center">
            Readings generated dynamically from the&nbsp;
            <a
              href={`https://bible.usccb.org//bible/readings/${helpers.formatDateUSCCB(date)}.cfm`}
              target="_blank"
              rel="noreferrer">
              USCCB website <Icon link name="external" size="small" />
            </a>
          </Container>
        </div>
      ) : (
        <Container textAlign={"center"} style={{ fontSize: "12pt" }}>
          Readings could not be loaded, visit the&nbsp;
          <a
            href={`https://bible.usccb.org//bible/readings/${helpers.formatDateUSCCB(date)}.cfm`}
            target="_blank"
            rel="noreferrer">
            USCCB website <Icon link name="external" size="small" />
          </a>
        </Container>
      )}
    </Segment>
  )
  /* const buildReflectionArea = () => (
    <Segment id="reflection">
      <Header as="h2" textAlign={"center"} style={{ textDecoration: "underline" }}>
        Today's Gospel Reflection
      </Header>
      {!reflectionLoaded ? (
        <Loader active inline="centered" />
      ) : reflection ? (
        <div>
          <div
            style={{
              paddingBottom: "25px",
              margin: "0 auto",
              maxWidth: "520px",
              fontSize: "12pt"
            }}
            dangerouslySetInnerHTML={{ __html: reflection }}></div>
          <Container textAlign="center">
            Reflection from the Bishop Barron's&nbsp;
            <a
              href="https://dailycatholicgospel.com/sign-up-daily-gospel"
              target="_blank"
              rel="noreferrer">
              Daily Gospel Reflections <Icon link name="external" size="small" />
            </a>
          </Container>
        </div>
      ) : (
        <Container textAlign={"center"} style={{ fontSize: "12pt" }}>
          Reflection not available
        </Container>
      )}
    </Segment>
  ) */

  return (
    <div style={{ marginTop: "35px" }}>
      {!readingsLoaded ? (
        <Loader active inline="centered" />
      ) : (
        <div>
          <Container text>
            {buildHeader()}
            {buildReadingsArea()}
            {/* buildReflectionArea() */}
          </Container>
        </div>
      )}
    </div>
  )
}
