import React, { useState } from "react"
import { Route, Switch } from "react-router-dom"

import * as helpers from "./helpers/helpers.js"

import AppHeader from "./AppHeader"
import Category from "./pages/Category"
import Text from "./pages/Text"
import ReadingsAndReflection from "./pages/ReadingsAndReflection"

import "./App.css"

const WHITE = "#ffffff"
const TAN = "#f2ece8"

const SERIF = "Georgia, Times, 'Times New Roman', serif"
const SANS_SERIF = "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif"

export default function App() {
  const [backgroundColor, setBackgroundColor] = useState(WHITE)
  const [font, setFont] = useState(SERIF)

  const toggleBackgroundColor = () =>
    backgroundColor === TAN ? setBackgroundColor(WHITE) : setBackgroundColor(TAN)

  const toggleFont = () => (font === SERIF ? setFont(SANS_SERIF) : setFont(SERIF))

  return (
    <div className={"main"} style={{ background: backgroundColor, fontFamily: font }}>
      <AppHeader toggleFont={toggleFont} toggleBackgroundColor={toggleBackgroundColor} />
      <Switch>
        <Route path="/categories/:category" component={Category} />
        <Route
          path="/readings/:date"
          component={(props) => (
            <ReadingsAndReflection
              date={props.match.params.date || helpers.getCurrentDate()}
              treatment="readings"
            />
          )}
        />
        <Route
          path="/readings"
          component={(props) => (
            <ReadingsAndReflection
              date={props.match.params.date || helpers.getCurrentDate()}
              treatment="readings"
            />
          )}
        />
        <Route path="/texts/:textAlias/:textLocation" component={Text} />
        <Route path="/texts/:textAlias" component={Text} />
        <Route path="/" component={() => <ReadingsAndReflection treatment="home" />} />
      </Switch>
      <br />
    </div>
  )
}
