import React, { useState, useEffect } from "react"
import { Container, Header, Segment, Loader, Input } from "semantic-ui-react"
import { useHistory } from "react-router-dom"
import { slide as SideMenu } from "react-burger-menu"
//import axios from "axios"

const TEXT_INDEXES = {
  "hail-mary": {
    alias: "hail-mary",
    category: "Prayers",
    index: [{ hasContent: true, indentationLevel: 0, locationWithinText: 1, title: "Hail Mary" }],
    title: "Hail Mary"
  },
  "nicene-creed": {
    alias: "nicene-creed",
    category: "Prayers",
    index: [
      { hasContent: true, indentationLevel: 0, locationWithinText: 1, title: "Nicene Creed" }
    ],
    title: "Hail Mary"
  },
  "glory-be": {
    alias: "glory-be",
    category: "Prayers",
    index: [{ hasContent: true, indentationLevel: 0, locationWithinText: 1, title: "Glory Be" }],
    title: "Our Father"
  },
  "our-father": {
    alias: "our-father",
    category: "Prayers",
    index: [{ hasContent: true, indentationLevel: 0, locationWithinText: 1, title: "Our Father" }],
    title: "Our Father"
  },
  "act-of-spiritual-communion": {
    alias: "act-of-spiritual-communion",
    category: "Prayers",
    index: [
      {
        hasContent: true,
        indentationLevel: 0,
        locationWithinText: 1,
        title: "Act of Spiritual Communion"
      }
    ],
    title: "Act of Spiritual Communion"
  },
  "apostles-creed": {
    alias: "apostles-creed",
    category: "catholictexts",
    index: [
      { hasContent: true, indentationLevel: 0, locationWithinText: 1, title: "Nicene Creed" }
    ],
    title: "Apostles' Creed"
  }
}
const TEXT_ENTRIES = {
  "hail-mary": {
    alias: "hail-mary",
    content: [
      "Hail, Mary, full of grace,<br>the Lord is with thee.<br>Blessed art thou among women<br>and blessed is the fruit of thy womb, Jesus.<br>Holy Mary, Mother of God,<br>pray for us sinners,<br>now and at the hour of our death.<br>Amen."
    ]
  },
  "nicene-creed": {
    alias: "nicene-creed",
    content: [
      "I believe in one God,<br>the Father almighty,<br>maker of heaven and earth,<br>of all things visible and invisible.<br><br>I believe in one Lord Jesus Christ,<br>the Only Begotten Son of God,<br>born of the Father before all ages.<br>God from God, Light from Light,<br>true God from true God,<br>begotten, not made, consubstantial with the Father;<br>through him all things were made.<br>For us men and for our salvation<br>he came down from heaven,<br>and by the Holy Spirit was incarnate of the Virgin Mary,<br>and became man.<br>For our sake he was crucified under Pontius Pilate,<br>he suffered death and was buried,<br>and rose again on the third day<br>in accordance with the Scriptures.<br>He ascended into heaven<br>and is seated at the right hand of the Father.<br>He will come again in glory<br>to judge the living and the dead<br>and his kingdom will have no end.<br><br><br>I believe in the Holy Spirit, the Lord, the giver of life,<br>who proceeds from the Father and the Son,<br>who with the Father and the Son is adored and glorified,<br>who has spoken through the prophets.<br><br>I believe in one, holy, catholic and apostolic Church.<br>I confess one Baptism for the forgiveness of sins<br>and I look forward to the resurrection of the dead<br>and the life of the world to come.<br><br>Amen."
    ]
  },
  "glory-be": {
    alias: "glory-be",
    content: [
      "Glory be to the Father<br>and to the Son<br>and to the Holy Spirit,<br>as it was in the beginning<br>is now, and ever shall be<br>world without end.<br>Amen."
    ]
  },
  "our-father": {
    alias: "our-father",
    category: "catholictexts",
    content: [
      "Our Father who art in heaven,<br>hallowed be thy name;<br>thy kingdom come.<br>thy will be done<br>on earth, as it is in heaven.<br>Give us this day our daily bread,<br>and forgive us our trespasses,<br>as we forgive those who trespass against us;<br>and lead us not into temptation,<br>but deliver us from evil.<br>Amen."
    ]
  },
  "act-of-spiritual-communion": {
    alias: "act-of-spiritual-communion",
    content: [
      "My Jesus,<br>I believe that You<br>are present in the Most Holy Sacrament.<br>I love You above all things,<br>and I desire to receive You into my soul.<br>Since I cannot at this moment receive You sacramentally,<br> come at least spiritually into my heart.<br>I embrace You as if You were already there<br>and unite myself wholly to You.<br>Never permit me to be separated from You.<br>Amen"
    ]
  },
  "apostles-creed": {
    alias: "apostles-creed",
    content: [
      "I believe in God,<br>the Father almighty,<br>Creator of heaven and earth,<br>and in Jesus Christ, his only Son, our Lord,<br>who was conceived by the Holy Spirit,<br>born of the Virgin Mary,<br>suffered under Pontius Pilate,<br>was crucified, died and was buried;<br>he descended into hell;<br>on the third day he rose again from the dead;<br>he ascended into heaven,<br>and is seated at the right hand of God the Father almighty;<br>from there he will come to judge the living and the dead.<br><br>I believe in the Holy Spirit,<br>the holy catholic Church,<br>the communion of saints,<br>the forgiveness of sins,<br>the resurrection of the body,<br>and life everlasting.<br><br>Amen."
    ]
  }
}

export default function Text(props) {
  const history = useHistory()
  const [textIndexResponse, setTextIndexResponse] = useState()
  const [textIndexResponseLoaded, setTextIndexResponseLoaded] = useState()
  const [filteredTextIndex, setFilteredTextIndex] = useState()
  const [textEntry, setTextEntry] = useState()
  const [textEntryLoaded, setTextEntryLoaded] = useState()
  const [textLocation, setTextLocation] = useState(null)

  //const backendURL = "https://catholictexts-backend.fly.dev"
  const isPrayer = textIndexResponse && textIndexResponse.category === "Prayers"
  const menuStyle = {
    bmBurgerButton: {
      position: "relative",
      width: "20px",
      height: "20px",
      left: "36px",
      top: "36px"
    },
    bmBurgerBars: {
      background: "#373a47"
    },
    bmBurgerBarsHover: {
      background: "#a90000"
    },
    bmCrossButton: {
      height: "24px",
      width: "24px"
    },
    bmCross: {
      background: "#bdc3c7"
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%"
    },
    bmMenu: {
      background: "white",
      paddingRight: "10px",
      paddingLeft: "10px"
    },
    bmMorphShape: {
      fill: "#373a47"
    },
    bmOverlay: {
      background: "#fffff"
    }
  }

  useEffect(() => {
    setTextEntryLoaded(false)
    loadTextIndex()

    // eslint-disable-next-line
  }, [props.match.params.textAlias])

  useEffect(() => {
    if (textIndexResponse && textLocation !== null) {
      loadTextEntry()
    }

    // eslint-disable-next-line
  }, [textIndexResponse, textLocation])

  function loadTextIndex() {
    console.log("Entering loadTextIndex")
    setTextIndexResponseLoaded(false)

    //const url = `${backendURL}/texts/index/${props.match.params.textAlias}`
    /*axios
      .get(url)
      .then((response) => {
        setTextIndexResponse(response.data)
        setFilteredTextIndex(response.data.index)
        setTextIndexResponseLoaded(true)

        const inputLocation = parseInt(props.match.params.textLocation) || 1
        const location = nextLocationInDirection("?+", inputLocation, response.data)
        setTextLocation(location)
      })
      .catch((error) => console.error(error))*/

    const fakeResponse = TEXT_INDEXES[props.match.params.textAlias]
    setTextIndexResponse(fakeResponse)
    setFilteredTextIndex(fakeResponse.index)
    setTextIndexResponseLoaded(true)

    const inputLocation = parseInt(props.match.params.textLocation) || 1
    const location = nextLocationInDirection("?+", inputLocation, fakeResponse)
    setTextLocation(location)
  }

  function loadTextEntry() {
    console.log("Entering loadTextEntry")

    setTextEntryLoaded(false)
    /*const url = `${backendURL}/texts/entry/${props.match.params.textAlias}/${textLocation}`
    axios
      .get(url)
      .then((response) => {
        setTextEntry(response.data)
        setTextEntryLoaded(true)
      })
      .catch((error) => console.error(error))*/

    const fakeResponse = TEXT_ENTRIES[props.match.params.textAlias]
    setTextEntry(fakeResponse)
    setTextEntryLoaded(true)
  }

  function handleLocationChange(nextLocation) {
    history.push(`/texts/${props.match.params.textAlias}/${nextLocation}`)
    setTextLocation(nextLocation)
  }

  function handleLocationChangeInDirection(direction) {
    const nextLocationWithContent = nextLocationInDirection(
      direction,
      textLocation,
      textIndexResponse
    )
    if (nextLocationWithContent) {
      handleLocationChange(nextLocationWithContent)
    }
  }

  function nextLocationInDirection(direction, currentLocation, textIndexResponse) {
    const directFunctionMapping = {
      "-": (currentLocation) => currentLocation - 1,
      "+": (currentLocation) => currentLocation + 1,
      "?+": (currentLocation) =>
        textIndexResponse.index[currentLocation - 1].hasContent
          ? currentLocation
          : currentLocation + 1
    }
    const directionFunction = directFunctionMapping[direction]
    let nextLocationWithContent = null
    let nextLocation = directionFunction(currentLocation)
    while (
      !nextLocationWithContent &&
      nextLocation > 0 &&
      nextLocation <= textIndexResponse.index.length
    ) {
      if (textIndexResponse.index[nextLocation - 1].hasContent) {
        nextLocationWithContent = nextLocation
      } else {
        nextLocation = directionFunction(nextLocation)
      }
    }
    return nextLocationWithContent
  }

  function hasNextLocationInDirection(direction) {
    return !!nextLocationInDirection(direction, textLocation, textIndexResponse)
  }

  const buildIndexMenu = () => {
    const indexContent = filteredTextIndex.map((entry) => {
      const classString = `
        ${entry.hasContent && entry.locationWithinText !== textLocation && "clickable"} 
        ${entry.locationWithinText === textLocation && "current"} 
        ${entry.indentationLevel === 0 && "zeroTier"} 
        ${entry.indentationLevel === 1 && "firstTier"} 
        ${entry.indentationLevel === 2 && "secondTier"} 
        ${entry.indentationLevel === 3 && "thirdTier"} 
        ${entry.indentationLevel === 4 && "fourthTier"}
      `
      return (
        <p
          key={entry.title}
          className={classString}
          onClick={() =>
            entry.hasContent &&
            entry.locationWithinText !== textLocation &&
            handleLocationChange(entry.locationWithinText)
          }>
          {entry.title}
        </p>
      )
    })
    return (
      <SideMenu styles={menuStyle} className="noselect">
        <Header as="h2" textAlign={"center"}>
          Text Index
        </Header>
        <Input placeholder="Filter..." style={{ marginBottom: "20px" }} onChange={handleFilter} />
        {indexContent}
        <br />
        <br />
        <br />
        <br />
      </SideMenu>
    )
  }

  function handleFilter(event) {
    const input = event.target.value
    if (input.length > 0) {
      console.log(input)
      const newFilteredTextIndex = textIndexResponse.index.filter((entry) =>
        entry.title.toLowerCase().includes(input.toLowerCase())
      )
      setFilteredTextIndex(newFilteredTextIndex)
    } else {
      setFilteredTextIndex(textIndexResponse.index)
    }
  }

  const buildEntryHeader = () => (
    <Header as="h3" textAlign={"center"}>
      {hasNextLocationInDirection("-") && (
        <span className={"navArrow"} onClick={() => handleLocationChangeInDirection("-")}>
          &#60;
        </span>
      )}
      <span style={{ marginLeft: "25px", marginRight: "25px" }}>
        {textLocation && textIndexResponse && textIndexResponse.index[textLocation - 1].title}
      </span>
      {hasNextLocationInDirection("+") && (
        <span className={"navArrow"} onClick={() => handleLocationChangeInDirection("+")}>
          &#62;
        </span>
      )}
    </Header>
  )

  const buildEntryContent = () =>
    console.log(textEntry) || (
      <Container>
        <div id="titleArea" style={{ marginTop: "35px", marginBottom: "35px" }}>
          <Header as="h1" textAlign={"center"}>
            {textIndexResponse.title}
          </Header>
          {!isPrayer && buildEntryHeader()}
        </div>
        {!textEntryLoaded ? (
          <Loader active inline="centered" />
        ) : (
          <Segment
            id="textEntry"
            textAlign={isPrayer ? "center" : "left"}
            style={{
              fontSize: "12pt"
            }}>
            {textEntry.content.map((entryLine, index) => (
              <p key={index} dangerouslySetInnerHTML={{ __html: entryLine }}></p>
            ))}
            {textEntry.link ? (
              <Container textAlign={"center"} style={{ marginTop: "35px" }}>
                Text generated dynamically from&nbsp;
                <a href={textEntry.link} target="_blank" rel="noreferrer">
                  {textEntry.link}
                </a>
              </Container>
            ) : null}
          </Segment>
        )}
      </Container>
    )

  return !textIndexResponseLoaded ? (
    <Loader active inline="centered" style={{ marginTop: "35px" }} />
  ) : (
    <div>
      {!isPrayer && buildIndexMenu()}
      {buildEntryContent()}
    </div>
  )
}
